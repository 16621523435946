import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import Homelayout from "../components/homelayout";
import SEO from "../components/seo";
import Modal from "../components/modal";

import IconOne from "../images/icon5.inline.svg";
import IconTwo from "../images/icon2.inline.svg";
import IconThree from "../images/icon1.inline.svg";
import IconFour from "../images/icon6.inline.svg";
import IconFive from "../images/icon4.inline.svg";
import IconSix from "../images/icon3.inline.svg";

import IconBox from "../images/box.inline.svg";

import IconDigital from "../images/digital.inline.svg";
import IconGlobe from "../images/globe.inline.svg";

import WhiteArrow from "../images/whitearrow.inline.svg";

import Shuffle from "../images/shuffle.inline.svg";
import CompOne from "../images/compoone.inline.svg";
import Phone from "../images/phone.inline.svg";

import Hand from "../images/hands.inline.svg";
import Gear from "../images/gear.inline.svg";

function IndexPage({ data, pageContext: { lang, currentPath } }) {
  return (
    <Homelayout>
      <SEO
        title="Home"
        description="LeverID is a single identity and signature platform for utilization by Government and Business verticals."
        image="https://www.leverid.com/thumbnail-new.png"
      />
      <Container className="home-first fullwidth banner-section">
        <Row className="container">
          <Col>
            <h1>
              A Post-Quantum Capable Digital Identity and Signature Platform
            </h1>
            <p>
              With its patent-pending technology, LeverID sets a new industry
              standard and is the “future delivered today” in secure digital
              authentication and signing.
            </p>
            <Modal />
          </Col>
          <Col className="bannerimghome">
            {/* <HomeSlide /> */}
            <Img fluid={data.homebglarge.childImageSharp.fluid} />
          </Col>
        </Row>
        <Row className="container icons-for-links homelast abovelinks">
          <Col>
            <Link to="/government-solutions">
              <h3>
                <IconGlobe />
                Government Solutions
              </h3>
              <h4>
                Discover more <WhiteArrow />
              </h4>
            </Link>
          </Col>
          <Col>
            <Link to="/corporate-solutions">
              <h3>
                <IconBox />
                Corporate Solutions
              </h3>
              <h4>
                Discover more <WhiteArrow />
              </h4>
            </Link>
          </Col>
          <Col>
            <Link to="/api-solutions">
              <h3>
                <IconDigital className="api-link" />
                API Solutions
              </h3>
              <h4>
                Discover more <WhiteArrow />
              </h4>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container className="home-third  fullwidth bluelines">
        <Container>
          <Row>
            <h2>Why choose LeverID?</h2>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconOne />
              </div>
              <h4>Post-Quantum Capable Design</h4>
              <p>
                The advent of quantum computing brings new security challenges.
                With this in mind, LeverID has been tailored to feature quantum
                capability.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconTwo />
              </div>
              <h4>Largely Scalable</h4>
              <p>
                Product design and infrastructure configuration enable vast
                scalability even by utilization of hundreds of millions of
                users.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconThree />
              </div>
              <h4>Attack-Tolerant and Reliable</h4>
              <p>
                LeverID features distributed private key implementation,
                ensuring no service breaks and reliable defense against threats.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconFour />
              </div>
              <h4>Fast Transaction Speed</h4>
              <p>
                Whether it’s authentication or signing, transactions speeds
                remain consistently swifter than any RSA-based solution.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconFive />
              </div>
              <h4>Ease of Integration</h4>
              <p>
                Being technically documented, ease of integration is enabled
                through API or a software development kit (SDK) for clients
                wishing to integrate LeverID.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconSix />
              </div>
              <h4>Mobile-First Design</h4>
              <p>
                Designed with mobile users in mind, our solution allows our
                users to effortlessly authenticate and sign from anywhere in the
                world.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="fullwidth home-fourth">
        <Container>
          <Row>
            <h2>LeverID consists of three components</h2>
          </Row>
          <Row className="plussign">
            <Col>
              <CompOne />
              <h3>Server Application & HSM</h3>
            </Col>
            <Col>
              <Phone />
              <h3>Mobile Device Application</h3>
            </Col>
            <Col>
              <Shuffle />
              <h3>APIs for Relying Parties</h3>
            </Col>
          </Row>
          <Row className="belowfourth">
            <Col>
              <p>
                The LeverID solution is especially{" "}
                <strong>designed for mobile devices.</strong>
              </p>
              <p>
                A mobile-first approach ensures that users are able to
                authenticate and digitally sign requests with ease, and{" "}
                <strong>on-the-go.</strong>
              </p>
            </Col>
            <Col>
              <p>
                When carrying out authentication processes, both the end-user
                and the server have{" "}
                <strong>independent distributed private keys.</strong>
              </p>
              <p>
                The server and client’s mobile device sign the document or
                authentication challenge independently with their private keys,
                providing for <strong>robust two-factor authentication.</strong>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="fullwidth home-laptop centered-text">
        <Container>
          <Row>
            <Col>
              <Img
                className="mobileonly"
                fluid={data.responsive_laptop.childImageSharp.fluid}
              />
              <Img
                className="desktoponly"
                fluid={data.laptop.childImageSharp.fluid}
              />
            </Col>
            <Col>
              <h2>LeverID in action - Leversign</h2>
              <p>
                One of many applications of LeverID comes in the form of
                Leversign, a platform created for end-users with the aim of
                making the digital signature process as straightforward and
                secure as possible for the end-user.
              </p>
              <a
                href="#"
                className="regular-button"
                style={{ display: "none" }}
              >
                Learn more about LeverSign
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="fullwidth home-fift centered-text">
        <Container>
          <Row>
            <Col>
              <h3>Post-Quantum Capable by Design</h3>
              <p>
                Quantum resilience is a quality that RSA encryption lacks today,
                due to the rate of advancements in quantum computing algorithms
                designed to brute force these functions.
              </p>
              <p>
                Conversely, the methods used today in LeverID cryptography are
                more complex than their RSA predecessors, and were{" "}
                <strong>designed with post-quantum capability in mind.</strong>
              </p>
            </Col>
            <Col>
              <Img fluid={data.placeholder.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.imagetwo.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>Based on Patent-Pending Technology</h3>
              <p>
                LeverID is based on patent-pending technology, thereby forging
                the path for{" "}
                <strong>
                  new industry standards, as well as yielding sustainability.
                </strong>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="fullwidth homelast">
        <Row className="cert">
          <Col>
            <h3>
              Designed to comply with
              <br />
              Certificates and Standards
            </h3>
            <p>
              Disclaimer: The certification process is ongoing and certificates
              are currently pending.
            </p>
          </Col>
          <Col>
            <h4>
              <Hand />
              <strong>eIDAS</strong> - Electronic IDentification, Authentication
              and trust Services.
            </h4>
            <h4>
              <Gear />
              <strong>Timestamping</strong> - Cryptographic protocol for
              certifying timestamps using X.509 certificates and public key
              infrastructure.
            </h4>
          </Col>
        </Row>
        <h2>Learn how you can benefit from LeverID</h2>
        <Row className="container icons-for-links">
          <Col>
            <Link to="/government-solutions">
              <h3>
                <IconGlobe />
                Government Solutions
              </h3>
              <h4>
                Discover more <WhiteArrow />
              </h4>
            </Link>
          </Col>
          <Col>
            <Link to="/corporate-solutions">
              <h3>
                <IconBox />
                Corporate Solutions
              </h3>
              <h4>
                Discover more <WhiteArrow />
              </h4>
            </Link>
          </Col>
          <Col>
            <Link to="/api-solutions">
              <h3>
                <IconDigital className="api-link" />
                API Solutions
              </h3>
              <h4>
                Discover more <WhiteArrow />
              </h4>
            </Link>
          </Col>
        </Row>
      </Container>
    </Homelayout>
  );
}

export const query = graphql`
  query {
    responsive_laptop: file(relativePath: { eq: "responsive_laptop_one.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    laptop: file(relativePath: { eq: "laptop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homebglarge: file(relativePath: { eq: "homenew.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    imagetwo: file(relativePath: { eq: "imagetwo.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default IndexPage;
