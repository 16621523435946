import React from 'react';
import LogoMain from '../images/logo.inline.svg';
import LogoLevercode from '../images/levlogo.inline.svg';
import { Link } from 'gatsby';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import facebook from '../images/facebook.png';
import linkedin from '../images/linkedin.png';

const Footer = ({}) => (
  <div id="footer">
    <Container>
      <Row className="first-footer">
        <Col>
          <LogoMain />
          <LogoLevercode />
          <p>
            LeverID is a single identity and signature platform for utilization
            by Government and Business verticals.
            <br />
            <br />A fully customizable platform with post-quantum capability,
            universal login functionality, and robust security.
          </p>
        </Col>
        <Col>
          <h4>Navigation</h4>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-lever-id">About LeverID</Link>
            </li>
            <li>
              <Link to="/government-solutions">Government Solutions</Link>
            </li>
            <li>
              <Link to="/corporate-solutions">Corporate Solutions</Link>
            </li>
            <li>
              <Link to="/api-solutions">API Solutions</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </Col>
        <Col>
          <h4>Contact</h4>
          <ul>
            <li>
              <a href="mailto:info@leverid.com">info@leverid.com</a>
            </li>
            <li>
              <a href="tel:+372 65 65 600">+372 65 65 600</a>
            </li>
            <li className="addresslink">
              <a
                href="https://www.google.com/maps?q=Tammsaare+%C3%84rikeskus,+Tallinn+13283,+Estonia&rlz=1C5CHFA_enEE876EE876&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjJn8mkprLwAhUfAxAIHc3kBIYQ_AUoAXoECAEQAw"
                target="_blank"
              >
                A. H. Tammsaare tee 47,
                <br /> Tallinn 11316, Estonia
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="last-footer">
        <Col>
          <p>Copyright {new Date().getFullYear()}. Lever ID OÜ</p>
        </Col>
        <Col className="footer-social">
          <a
            href="https://www.linkedin.com/company/leverid"
            target="_blank"
            className="social-icon xl:mr-12"
            rel="noopener noreferrer nofollow"
          >
            <img src={linkedin} />
          </a>
        </Col>
        <Col>
          <ul>
            <li>
              <Link to="/privacy_notice">Privacy Notice</Link>
            </li>
            <li>
              <Link to="/terms_of_service">Terms of Service</Link>
            </li>
            <li>
              <a href="https://levercode.com/" target="_blank">
                Levercode.com
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
